'use strict';

if (DEV) requirejs.config({ urlArgs: function urlArgs() {
		return ".src.js";
	} });

function ready_to_load_modules() {
	require(['getflow'], function (getflow) {
		function PubSub() {
			var ___ = [];

			return {
				send: function send() {
					var _arguments = arguments;

					___.forEach(function (s) {
						return s.apply(null, _arguments);
					});
				},

				fire: function fire(news) {
					___.forEach(function (s) {
						return s(news);
					});
				},

				on: function on(s) {
					if (___.indexOf(s) < 0) ___.push(s);
				},

				off: function off(s) {
					var i = ___.indexOf(s);
					if (i > -1) ___.splice(i, 1);
				}
			};
		}
		window.PubSub = PubSub; // Yeah, it's another global.
		var navigating = PubSub();
		window.navigating = navigating;

		// Returns a shallow copy of the parts of a Location within the domain.
		function clone_location(_) {
			return {
				pathname: _.pathname,
				search: _.search,
				hash: _.hash
			};
		}

		// Add a hook to history.pushState.
		function onpushstate(action) {
			var history = window.history;
			var real_pushState = history.pushState;

			// Don't make this an arrow function!  It uses `arguments`.
			history.pushState = function (state) {
				var old_location = clone_location(window.location);
				var real_result = real_pushState.apply(history, arguments);

				// Fire our hook *after* calling the native version, so that
				// window.location will reflect the new path, not the old one.
				action(state, old_location);

				return real_result;
			};
		}

		function that_happens_on_each_location(new_place, old_place) {
			navigating.fire({ new_place: new_place, old_place: old_place });

			var more_modules = [].slice.call(document.head.querySelectorAll('meta[itemprop="module"]')).map(function (meta) {
				return meta.content;
			});

			// Load other scripts.
			require(more_modules, function () {}, function (error) {
				console.log("oops no things", arguments);
			});
		}

		// YOU'D HAVE to do this on popstate as well, right?
		onpushstate(that_happens_on_each_location);
		that_happens_on_each_location(clone_location(window.location));

		window.addEventListener('popstate', function (event) {
			that_happens_on_each_location(clone_location(window.location));
		});
	});
}

(function () {
	if (/Trident|MSIE/.test(navigator.userAgent)) return;
	if (Promise) ready_to_load_modules();else require(['es6-promise.min'], function (__) {
		window.Promise = __.Promise;
		ready_to_load_modules();
	});
})();

